import httpManager from "../axios/httpManager";


export function getPhoneCodeList(search, pageNum, pageSize) {
    let url = '/verificationcode/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}

export function updatePhoneCodeStatus(record) {
    let url = '/verificationcode/update';
    return httpManager.post(url, record);
}

export function deletePhoneCode(record) {
    let url = '/verificationcode/deleteVerification';
    return httpManager.post(url, record);
}
